/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { mongoFunctionsClient } from '../modules/functions/client';

export default class ThreadService {
  public async getAllThreads() {
    const result = await mongoFunctionsClient.getAllThreads();

    return result || [];
  }

  public async getSortedThreads() {
    const threads = await this.getAllThreads();

    return threads;
  }

  public async getThreadById(threadId: string) {
    const result = await mongoFunctionsClient.getMessagesByThreadId(threadId);

    return result;
  }
}
