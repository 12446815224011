import { hasura, hasuraQueries } from '../modules/hasura';

export default class ThreadService {
  public async createThreadId(name) {
    const result = await hasura.query(hasuraQueries.createThreadId(name));

    return result?.insert_thread?.returning[0]?.id || null;
  }

  public async addMessageToThread({ query, response, thread }) {
    const result = await hasura.query(hasuraQueries.insertMessageToThread(), {
      objects: [
        {
          question: query,
          answer: response,
          source_id: thread.messages[thread.messages.length - 1].source_id,
          agent_id: thread.messages[thread.messages.length - 1].agent_id,
          recommendations: JSON.stringify(
            thread.messages[thread.messages.length - 1].recommendations
          ),
          citations: JSON.stringify(
            thread.messages[thread.messages.length - 1].citations
          ),
          image_url: JSON.stringify(
            thread.messages[thread.messages.length - 1].image_url
          ),
          tooltip: JSON.stringify(
            thread.messages[thread.messages.length - 1].tooltip
          ),
          step: JSON.stringify(
            thread.messages[thread.messages.length - 1].step
          ),
          thread_id: thread.id,
        },
      ],
    });

    return result?.insert_message?.returning[0] || null;
  }

  public async createThread({
    question,
    answer,
    source: source_id,
    agent: agent_id,
    thread,
  }) {
    const threadId = await this.createThreadId(question);
    const result = await hasura.query(hasuraQueries.createThread(), {
      objects: [
        {
          thread_id: threadId,
          question,
          answer,
          source_id,
          agent_id,
          recommendations: JSON.stringify(thread.messages[0].recommendations),
          citations: JSON.stringify(thread.messages[0].citations),
          image_url: JSON.stringify(thread.messages[0].image_url),
          tooltip: JSON.stringify(thread.messages[0].tooltip),
          step: JSON.stringify(thread.messages[0].step),
        },
      ],
    });

    return result?.insert_message?.returning?.[0] || null;
  }

  public async getAllThreads() {
    const result = await hasura.query(hasuraQueries.getThreads());

    return result?.thread || [];
  }

  public async getSortedThreads() {
    const threads = await this.getAllThreads();

    return this.sortThreads(threads);
  }

  public async getThreadById(payload) {
    const result = await hasura.query(
      hasuraQueries.getThreadByThreadId(payload)
    );

    return result?.message;
  }

  public sortThreads(threads) {
    return threads.sort((a, b) => a.id - b.id);
  }
}
