import { BrowserCacheLocation } from '@azure/msal-browser';

interface AzureConfiguration {
  auth: {
    clientId: string;
    authority: string;
    redirectUri: string;
  };
  cache: {
    cacheLocation: BrowserCacheLocation;
  };
}

interface AzureScope {
  scopes: string[];
  claims: string;
}

interface AzureConfigurations {
  config: {
    [key: string]: AzureConfiguration;
  };
  scope: {
    [key: string]: AzureScope;
  };
}

const clientAndTenantId = {
  '90north': {
    clientId: 'ed3e8d6d-3b70-4aa5-92b1-5bf21b98fd82',
    tenantId: 'd026e4c1-5892-497a-b9da-ee493c9f0364',
  },
  dsi: {
    clientId: '36f17c79-5d6e-468c-abc8-e89e375851ca',
    tenantId: 'b0179487-3026-44f7-bcd6-25ed452138d0',
  },
  ciam: {
    clientId: '1de7aba7-4dca-45a3-9e89-bc0c655215f7',
    tenantId: '51bf92f8-44e5-4124-a114-f6cd68f4a81a',
  },
};

const azureConfigurations: AzureConfigurations = Object.freeze({
  config: {
    '90north': {
      auth: {
        clientId: clientAndTenantId['90north'].clientId,
        authority: `https://login.microsoftonline.com/${clientAndTenantId['90north'].tenantId}`,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (!containsPii) {
              console.log(message);
            }
          },
          piiLoggingEnabled: false,
        },
      },
    },
    dsi: {
      auth: {
        clientId: clientAndTenantId.dsi.clientId,
        authority: `https://login.microsoftonline.com/${clientAndTenantId.dsi.tenantId}`,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (!containsPii) {
              console.log(message);
            }
          },
          piiLoggingEnabled: false,
        },
      },
    },
    ciam: {
      auth: {
        clientId: clientAndTenantId.ciam.clientId,
        authority: `https://ihcexternal.ciamlogin.com/${clientAndTenantId.ciam.tenantId}`,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (!containsPii) {
              console.log(message);
            }
          },
          piiLoggingEnabled: false,
        },
      },
    },
  },
  scope: {
    '90north': {
      scopes: [
        'openid',
        'profile',
        'email',
        `api://${clientAndTenantId['90north'].clientId}/User.Read`,
        // "api:new-client_id/.default"
      ], // Add necessary scopes
      claims: JSON.stringify({
        id_token: {
          email: { essential: true },
        },
      }),
    },
    dsi: {
      scopes: [
        'openid',
        'profile',
        'email',
        `api://${clientAndTenantId.dsi.clientId}/User.Read`,
        // "api:new-client_id/.default"
      ], // Add necessary scopes
      claims: JSON.stringify({
        id_token: {
          email: { essential: true },
        },
      }),
    },
    ciam: {
      scopes: [
        'openid',
        'profile',
        'email',
        `api://${clientAndTenantId.ciam.clientId}/User.Read`,
        // "api:new-client_id/.default"
      ], // Add necessary scopes
      claims: JSON.stringify({
        id_token: {
          email: { essential: true },
        },
      }),
    },
  },
});

export {
  azureConfigurations,
  AzureConfiguration,
  AzureScope,
  AzureConfigurations,
};
