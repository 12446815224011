import { mondayClient, MondayClient } from './client';
import { mondayQueries } from './queries';

class MondayDataProvider {
  private _client: MondayClient;

  constructor(client = mondayClient) {
    this._client = client;
  }

  async query(query, variables = {}) {
    const result = await this._client.load(query, variables);
    return result;
  }
}

const monday = new MondayDataProvider();

export { monday, mondayQueries };
