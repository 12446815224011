enum PageElement {
  ADD_COLLECTION = 'ADD_COLLECTION_BUTTON',
  AGENTS = 'AGENTS_BUTTON',
  COPY_ANSWER = 'COPY_ANSWER',
  COPY_TABLE = 'COPY_TABLE',
  DOWNLOAD_ATTACHMENTS = 'DOWNLOAD_ATTACHMENTS',
  FEEDBACK = 'FEEDBACK',
  HELP = 'HELP_BUTTON',
  NEW_THREAD = 'NEW_THREAD_BUTTON',
  SEND = 'SEND_BUTTON',
  SOURCES = 'SOURCES_BUTTON',
  SUGGESTED_QUERY = 'SUGGESTED_QUERY_BUTTON',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS_BUTTON',
  TOUR = 'TOUR_BUTTON',
  UPLOAD = 'UPLOAD_BUTTON',
}

export { PageElement };
