/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ChatModelsState {
  list: Array<any> | undefined;
  selected: any | undefined;
  chatModelsLoading: boolean;
}

// Define the initial state using that type
const initialState: ChatModelsState = {
  list: undefined,
  selected: undefined,
  chatModelsLoading: false,
};

const chatModelsSlice = createSlice({
  name: 'chatModels',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setChatModels: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setSelectedChatModel: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
    setChatModelsLoading: (state, action: PayloadAction<boolean>) => {
      state.chatModelsLoading = action.payload;
    },
  },
});

const { setChatModels, setSelectedChatModel, setChatModelsLoading } =
  chatModelsSlice.actions;
const chatModelsReducer = chatModelsSlice.reducer;

export {
  ChatModelsState,
  chatModelsReducer,
  setChatModels,
  setSelectedChatModel,
  setChatModelsLoading,
};
