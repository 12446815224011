import {
  ADELE_ENDPOINT,
  AUTH_FUNCTIONS_ENDPOINT,
  FUNCTIONS_ENDPOINT,
  MODELS_ENDPOINT,
} from '../../constants';
import { axios, Axios } from '@bundled-es-modules/axios';
import { getHasuraToken } from '../hasura/client';
import { delay } from '../../utils';

interface SetThemeSummariesBody {
  hasura_token: string | null;
  sub_theme_id?: number;
  macro_theme_id?: number;
}

interface SetThemeFunctionParams {
  subThemeId?: number;
  macroThemeId?: number;
}

const getToken = async () => {
  const { store } = await import('../../state/store');

  return store.getState().token.value?.accessToken;
};

async function getaccessToken() {
  const token = await getToken();
  if (token != null) {
    return token;
  }
  await delay(500);
  return getaccessToken();
}

class FunctionsClient {
  private client: Axios | null = null;
  public hasuraToken: string | null = null;
  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    this.hasuraToken = await getHasuraToken();
    this.client = axios.create({
      baseURL: FUNCTIONS_ENDPOINT,
    });
  }

  async setThemeSummaries({
    subThemeId,
    macroThemeId,
  }: SetThemeFunctionParams) {
    const body: SetThemeSummariesBody = {
      hasura_token: this.hasuraToken,
    };

    if (subThemeId != null) {
      body['sub_theme_id'] = subThemeId;
    }

    if (macroThemeId != null) {
      body['macro_theme_id'] = macroThemeId;
    }

    return this.post('/set-theme-summaries', body);
  }

  executeMessageOptimizer(behavioralOption, userInput) {
    const body = {
      chatToken: this.hasuraToken,
      behavioral_option: behavioralOption,
      user_input: userInput,
    };

    return this.post('/message-optimizer/generate', body);
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}

class AuthFunctionsClient {
  private client: Axios | null = null;
  public accessToken: string | null | undefined = null;
  public initComplete: Promise<void>;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    // @ts-ignore
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: AUTH_FUNCTIONS_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ContentType: 'application/json',
      },
    });
  }

  getClaims(id) {
    return this.getWithHeaders('/claims', id);
  }

  getClaimsAccounts() {
    return this.get('/claims-accounts');
  }

  getAdminData(id) {
    return this.getWithHeaders('/admin-data', id);
  }

  saveAdminData(payload, id) {
    return this.postWithHeaders('/admin-data', payload, id);
  }

  async getWithHeaders(path, id) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    this.client.defaults.headers.common['selected-account-id'] = id;
    this.client.defaults.headers.common['protocol-version'] = '2';
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async get(path) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.get(path);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async postWithHeaders(path, body, id) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    this.client.defaults.headers.common['selected-account-id'] = id;
    this.client.defaults.headers.common['protocol-version'] = '2';
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }

  async post(path, body) {
    await this.initComplete;
    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }
    const response = await this.client.post(path, body);
    if (response.data?.data) {
      return response.data?.data;
    }
    return response.data;
  }
}

class ModelFunctionsClient {
  private client: Axios | null = null;
  public initComplete: Promise<void>;
  public accessToken: string | null | undefined = null;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: MODELS_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  async post(path, body) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    const response = await this.client.post(path, body);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  async get(path) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    const response = await this.client.get(path);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  getChatModels(chatToken) {
    const body = {
      chatToken,
    };

    return this.post('/hate-audit-models', body);
  }

  async getCitation(refId) {
    return { [refId]: await this.get(`get-citation/${refId}`) };
  }
}

class UploadFunctionsClient {
  private client: Axios | null = null;
  public initComplete: Promise<void>;
  public accessToken: string | null | undefined = null;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: 'https://northstar-staging-app-fastapi.azurewebsites.net/',
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  async post(path, body) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    // this.client.defaults.headers.common['Sec-Fetch-Mode'] = 'cors';
    // this.client.defaults.headers.common['Sec-Fetch-Site'] = 'cross-site';
    const response = await this.client.post(path, body);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  async get(path) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    const response = await this.client.get(path);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  uploadFiles(formData) {
    return this.post('/adele/upload', formData);
  }

  vanillaUploadFiles(formData) {
    return this.post('/adele/vanilla-upload', formData);
  }
}

class AdeleClient {
  private client: Axios | null = null;
  public initComplete: Promise<void>;
  public accessToken: string | null | undefined = null;

  constructor() {
    this.initComplete = this._setAxiosInstance();
  }

  async _setAxiosInstance() {
    this.accessToken = await getaccessToken();
    this.client = axios.create({
      baseURL: ADELE_ENDPOINT,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        contentType: 'application/zip',
      },
      responseType: 'blob',
    });
  }

  async post(path, body) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Adele client is not initialized');
    }

    const response = await this.client.post(path, body);

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  async get(path) {
    await this.initComplete;

    if (this.client == null) {
      throw new Error('Functions client not initialized');
    }

    const response = await this.client.get(path, { reesponseType: 'blob' });

    if (response.data?.data) {
      return response.data?.data;
    }

    return response.data;
  }

  getTable(payload: { thread_id: any; message_id: any }) {
    return this.get(
      `/get-table?thread_id=${payload.thread_id}&message_id=${payload.message_id}`
    );
  }
}

const functionsClient = new FunctionsClient();
const authFunctionsClient = new AuthFunctionsClient();
const modelFunctionsClient = new ModelFunctionsClient();
const uploadFunctionsClient = new UploadFunctionsClient();
const adeleClient = new AdeleClient();

export {
  modelFunctionsClient,
  functionsClient,
  authFunctionsClient,
  uploadFunctionsClient,
  adeleClient,
  AuthFunctionsClient,
  FunctionsClient,
  ModelFunctionsClient,
  UploadFunctionsClient,
  AdeleClient,
};
