import { html } from 'lit';
import { property } from 'lit/decorators.js';
import '@mch/nn-web-viz/dist/nn-button';
import '@mch/nn-web-viz/dist/nn-spinner';

import { NNBase } from '@mch/nn-web-viz/dist/packages/base/Base';

import './adele-shell';
import { connect, store } from './state/store';
import { setUser } from './state/slices/user';
import { setaccessToken } from './state/slices/token';
import { setAccounts } from './state/slices/appConfig';

import { Notification } from '@vaadin/notification/vaadin-notification.js';
import {
  getClaimsAccountsFromServer,
  getDefaultAccountFromClaims,
  setDataToStore,
} from './modules/claims';

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const LOGIN_REQUEST = Object.freeze({
  scopes: [
    'openid',
    'profile',
    'email',
    `api://${process.env.CLIENT_ID}/User.Read`,
    // "api:new-client_id/.default"
  ], // Add necessary scopes
  claims: JSON.stringify({
    id_token: {
      email: { essential: true },
    },
  }),
});

class AuthShell extends connect(store)(NNBase) {
  @property({ type: Object }) user = null;
  @property({ type: String }) _authToken = null;
  @property({ type: Object }) _claimsData = null;
  @property({ type: Boolean }) _loggingIn: boolean = false;

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  constructor() {
    super();

    this._loginWithMsAd();
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('_claimsData')) {
      if (this._claimsData != null) {
        this._loggingIn = false;
      }
    }
  }

  get isLoggedIn() {
    return !!this._authToken && !!this._claimsData;
  }

  async _getMsAdClient() {
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    // Handle the redirect response
    msalInstance
      .handleRedirectPromise()
      .then((response: AuthenticationResult | null) => {
        if (response) {
          this._saveLoginInformation(response);
        }
      })
      .catch(error => {
        console.error('Redirect error: ', error);
      });

    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    msalInstance.enableAccountStorageEvents();
    msalInstance.addEventCallback((event: any) => {
      if (!event) return;

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        const account = (event.payload as AuthenticationResult)?.account; // Cast to AuthenticationResult
        if (account) {
          msalInstance.setActiveAccount(account);
        }
      }
    });
    return msalInstance;
  }

  async _fetchTokens() {
    const resultClaimsAccounts = await getClaimsAccountsFromServer();

    if (resultClaimsAccounts.length === 0) {
      store.dispatch(setAccounts([]));

      this._claimsData = null;

      Notification.show(
        'No account was assigned to this record, please contact your administrator.',
        {
          position: 'bottom-end',
          duration: 0,
          theme: 'error',
        }
      );
    } else {
      const currentAccount = await getDefaultAccountFromClaims(
        resultClaimsAccounts
      );

      if (currentAccount != null) {
        setDataToStore(currentAccount);

        this._claimsData = currentAccount;
      }
    }
  }

  async _logoutWithMsAd() {
    const instance = await this._getMsAdClient();

    instance.logoutPopup().then(() => {
      this._authToken = null;
      this.user = null;
    });
  }

  async _saveLoginInformation(result) {
    if (!result) return;

    const userData = JSON.parse(atob(result?.accessToken.split('.')[1]));

    store.dispatch(
      setUser({
        nickname: `${userData?.given_name.replace(
          /([a-z])([A-Z])/g,
          '$1 $2'
        )} ${userData?.family_name}`,
        picture: null,
      })
    );
    store.dispatch(setaccessToken(result?.accessToken));

    this._authToken = result?.accessToken;

    await this._fetchTokens();
  }

  async _loginWithMsAd() {
    this._loggingIn = true;

    const instance = await this._getMsAdClient();

    let result: AuthenticationResult | null = null;

    try {
      result = await instance.acquireTokenSilent(LOGIN_REQUEST);
    } catch (error) {
      // Use redirect instead of popup
      instance.loginRedirect(LOGIN_REQUEST);
    }

    this._saveLoginInformation(result);
  }

  render() {
    if (this._loggingIn && !this.isLoggedIn) {
      return html`<nn-spinner theme="book"></nn-spinner>`;
    } else {
      if (!this.isLoggedIn) {
        return html`<div
          style="width: 220px; top: 50%; left:50%; transform: translate(-50%, -50%); position: absolute; color: white; text-align: center;"
        >
          <nn-button style="width: 100%;" @click=${this._loginWithMsAd}>
            Log in
          </nn-button>
        </div>`;
      }
    }

    return html`
      <adele-shell
        @login=${this._loginWithMsAd}
        @logout=${this._logoutWithMsAd}
        @refresh-tokens=${this._fetchTokens}
      >
      </adele-shell>
    `;
  }
}

export { AuthShell };
