import { Account, UserTracker } from '../../types';
import { EventType } from './enums/event-type';
import { Page } from './enums/page';
import { PageElement } from './enums/page-element';
import { Topics } from './enums/topics';
import { getDeviceType } from './mobile';
import { getOS } from './os';
import { Analytics } from './types/analytics';
import { Event } from './types/event';

let _trackingPageView = false;
let _currentPage: Page | null = null;
let _currentTime = 0;
let _trackedEvents: Event[] = [];
let _previousPage: Page | null = null;
let _currentAccount: Account | null = null;
let _currentUser: UserTracker | null = null;

let timer;

/**
 * Reset page event.
 * @private
 */
const _resetPageEvent = () => {
  clearInterval(timer);

  _trackingPageView = false;
  _currentPage = null;
  _currentTime = 0;
  _trackedEvents = [];
  _previousPage = null;
};

const createAnalytics = () => {
  const analytics: Analytics = {
    type: EventType.PAGETIME,
    page: _currentPage || Page.HOME,
    events: _trackedEvents,
    value: _currentTime,
    user: _currentUser || { id: 0, name: 'Error fetching user' },
    account: _currentAccount || { id: 0, name: 'Error fetching account' },
    os: getOS() || 'Unknown OS',
    device: getDeviceType() || 'Unknown Device',
    referrer: document.referrer,
  };

  return analytics;
};

/**
 * Track currently logged in user.
 * @public
 * @param user Currently logged in user.
 */
const trackUser = (user: UserTracker) => {
  const { id, name } = user;

  _currentUser = { id, name };
};

/**
 * Track currently logged in account (e.g. 90North).
 * @public
 * @param account Currently logged in account.
 */
const trackAccount = (account: Account) => {
  _currentAccount = account;
};

/**
 * Track current page view.
 * This function will detect when there is a change in page and will send the analytics to the server.
 * @param param Current page.
 */
const trackPageView = ({ page }: { page: Page }) => {
  _trackingPageView = true;

  if (_currentPage !== page) {
    /**
     * If the current page is not the same as the new page, then we need to send the analytics to the server.
     */
    if (_trackingPageView && _currentPage != null) {
      const analytics: Analytics = createAnalytics();

      // SENDING OF ANALYTICS TO SERVER SHOULD BE MADE HERE
      console.log('Sending analytics to server: ', analytics);

      _resetPageEvent();
    }

    /**
     * Start the timer to track the time spent on the page.
     */
    timer = setInterval(() => {
      _currentTime += 1;
    }, 1000);

    /**
     * Set the current page to the new page.
     */
    _currentPage = page;
  }
};

/**
 * Track click events.
 * @public
 * @param eventSource HTML elements or Topics.
 * @param toggleProperty For events that rely on flags like opening or closing a modal.
 */
const trackClickEvent = (
  eventSource: PageElement | Topics,
  toggleProperty = false
) => {
  switch (eventSource) {
    case PageElement.SOURCES:
    case PageElement.AGENTS:
      if (!toggleProperty) {
        _trackButtonClick({ element: eventSource });
      }
      break;

    default:
      _trackButtonClick({ element: eventSource });
      break;
  }
};

/**
 * Track button click event.
 * @private
 * @param param Element to track.
 */
const _trackButtonClick = ({ element }) => {
  if (_currentPage == null) return;

  const index = _trackedEvents.findIndex(
    event => event.element != null && event.element === element
  );

  if (index !== -1) {
    _trackedEvents[index] = {
      ..._trackedEvents[index],
      value: _trackedEvents[index].value + 1,
    };
  } else {
    _trackedEvents = [
      ..._trackedEvents,
      {
        type: EventType.CLICK,
        element: element,
        value: 1,
      },
    ];
  }
};

/**
 * End tracking. This function will log the final events of the user.
 * @returns void
 */
const endTracking = () => {
  if (_currentPage == null) return;

  const analytics: Analytics = createAnalytics();

  // SENDING OF ANALYTICS TO SERVER SHOULD BE MADE HERE
  console.log('Sending analytics to server: ', analytics);

  _resetPageEvent();
};

/**
 * Set previous page.
 * Specifically for tracking the page where the new thread popup was triggered.
 */
const setPreviousPage = () => {
  _previousPage = _currentPage;
};

/**
 * Track previous page.
 * Track page before the new thread popup was triggered.
 */
const trackPreviousPage = () => {
  trackPageView({ page: _previousPage || Page.HOME });
};

export {
  endTracking,
  trackPreviousPage,
  setPreviousPage,
  trackPageView,
  trackClickEvent,
  trackAccount,
  trackUser,
};
